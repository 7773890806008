@import '../../utils/_colors.scss';

.detail {
  margin-bottom: 2rem;

  h3 {
    margin-bottom: 3rem;
  }

  .detail-header {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .back{
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .btn {
      display: flex;
      align-items: center;

      span {
        margin-top: -1px;
        margin-left: 0.3rem;
      }
    }
  }

  .input-fields .form-group {
    max-width: 320px;
  }

  .form-group {
    margin-right: .5rem;
  }

  .inline-block {
    display: inline-block;
    margin-bottom: 2rem;
  }

  .italic {
    font-style: italic;
  }

  .none {
    display: none;
  }

  .title-deprecated {
    font-size: 1.3rem;
    font-weight: 300;
    margin-left: 0.5rem;
    color: $dark-grey;
  }

  .input-fields {
    margin: 0.5rem 0 5rem;
  }

  .detail-actions.flex-end {
    justify-content: flex-end;
  }

  .detail-actions{
    border-top: 1px solid $dark-grey;
    margin-top: 2rem;
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;

    .btn.btn-primary{
      margin-left: 1rem;
    }
  }
  
  .btn-primary--margin-right{
    margin-right: 1rem;
  }
}
