@import '../../utils/_colors.scss';

.overview{
  h2 {
    margin: 1rem 0 1rem 0;

    .sort-label {
      font-size: 1.5rem;
      font-weight: 300;
      margin-left: 0.5rem;
      color: $dark-grey;
    }
  }

  .overview-settings{
    margin: 1.5rem 0;
    display: flex;
    justify-content: space-between;

    .btn {
      display: flex;
      align-items: center;
      white-space: nowrap;
      span {
        margin-top: -1px;
        margin-left: 0.3rem;
      }
    }
  }

  .jumbotron {
    margin-top: 2rem;
    background: $light-grey;
    border: 1px solid $light-border;

    .empty-overview {
      display: block;
      margin: auto;
      text-align: center;
      color: $dark-grey;
    }
  }
}
