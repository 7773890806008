@import './utils/_colors.scss';

html,
body {
  font-size: 14px;
}

body {
  font-size: 1rem;
}

main {
  margin-top:56px;
}

.unauth-layout {
  display: flex;
  align-items: center;
  min-height: 100vh;
  background: $light-grey;
}

.hint{
  font-size: 12px;
  color: grey;
}