.btn-create img   {
  margin-right: 0.75rem;
  width: 16px;
}

.create-modal-input {
 .form-check{
   margin-bottom: 1rem;
 }
}
