@import '../../utils/_colors.scss';

.choose-password-container.container{
  max-width: 600px;

  .choose-password {
    padding: 2rem;
    margin: 0;
    background: $white;
    border: 1px solid $light-border;
    border-radius: 5px;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;

    .password-input {
      margin: 2rem 0;
    }
  }
}
