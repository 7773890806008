// Silverback default colors
$light-grey: rgb(232,235,237);
$dark-grey: rgb(81,98,111);

$light-border: rgb(232,235,237);

$shadow: 0 0 7px 0px rgba(0,0,0,0.15);

$warning: #dc3545;
$info: #17a2b8;


// Colors from the app
$primary: rgb(141,11,61);
$primaryLight: rgb(164,0,67);
$primaryTextLight: rgb(145,83,113);
$secondary: rgb(240,169,63);
$grey: rgb(81,98,111);
$grey1: rgb(151,159,170);
$grey2: rgb(232,235,237);
$white: rgb(255,255,255);
$black: rgb(0,0,0);
$transparent: transparent;
$transparentBlack: rgba(0,0,0,0.2);
$transparentBlackDark: rgba(0,0,0,0.8);
$transparentGrey: rgba(248,248,248,0.8);
