@import '../../utils/_colors.scss';

.login-container.container{
  max-width: 600px;

  .login {
    padding: 2rem;
    margin: 0;
    background: $white;
    border: 1px solid $light-border;
    border-radius: 5px;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;

    .forgot-password-link{
      margin-left: 1rem;
    }


    .branding{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid $light-border;

      img {
        height: 5rem;
      }

      h2 {
        margin-top: 1rem;
        color: $primary;
        font-size: 1.2rem;
      }

      @media screen and (max-width: 576px) {
        border-right: none;
        border-bottom: 1px solid $light-border;
      }
    }
  }
}
