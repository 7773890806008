.modal.fade.show {
  display: block;
  opacity: 1;
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.modal-backdrop.fade.show{
  opacity: 0.5;
  -webkit-animation: fadeInBackdrop 0.5s;
  animation: fadeInBackdrop 0.5s;
}

.modal.fade.show{
  overflow: auto;
}

@-webkit-keyframes fadeInBackdrop {
  from { opacity: 0; }
    to { opacity: 0.5; }
}
@keyframes fadeInBackdrop {
  from { opacity: 0; }
    to { opacity: 0.5; }
}