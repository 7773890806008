@import '../../utils/_colors.scss';

.row .sidebar {
  position: fixed;
  top: 51px;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 20px 0;
  background: $white;
  border-right: 1px solid $light-border;

  .nav-pills .active {
    border-radius: 0;
  }

  .versioning {
    bottom: 0;
    position: absolute;
    padding: 1.5rem 1.3rem;
    color: $dark-grey;

    .version-platform {
      margin-top: 1rem;
      font-weight: bold;
    }
  }
}
